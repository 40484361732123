/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BannerSize {
    SIZE__CUSTOM = 'size_-custom',
    LEADERBOARD_728X90 = 'leaderboard_728x90',
    MEDIUM_RECTANGLE_300X250 = 'medium-rectangle_300x250',
    WIDE_SKYSCRAPER_160X600 = 'wide-skyscraper_160x600',
    MOBILE_LEADERBOARD_320X50 = 'mobile-leaderboard_320x50',
    HALF_PAGE_300X600 = 'half-page_300x600',
    SIZE_300X50 = 'size_300x50',
    SIZE_970X90 = 'size_970x90',
    SIZE_970X250 = 'size_970x250',
    SIZE_1X1 = 'size_1x1',
    SIZE_460X120 = 'size_460x120',
    SIZE_570X70 = 'size_570x70',
    SIZE_120X240 = 'size_120x240',
    SIZE_650X136 = 'size_650x136',
    SIZE_1200X628 = 'size_1200x628',
    SIZE_320X480 = 'size_320x480',
    SIZE_640X480 = 'size_640x480',
    SIZE_640X640 = 'size_640x640',
    SIZE_120X600 = 'size_120x600',
    SIZE_1920X1080 = 'size_1920x1080',
    SIZE_768X1024 = 'size_768x1024',
    SIZE_1024X768 = 'size_1024x768',
    SIZE_320X100 = 'size_320x100',
    SIZE_300X1050 = 'size_300x1050',
    SIZE_250X250 = 'size_250x250',
    SIZE_336X280 = 'size_336x280',
    SIZE_480X320 = 'size_480x320',
    SIZE_320X250 = 'size_320x250',
    SIZE_970X550 = 'size_970x550',
    SIZE_468X60 = 'size_468x60',
}
