/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdGroupsQuickStatsFilterKey {
    LAUNCHING_IN_DAYS = 'launching-in-days',
    LAUNCHED_IN_DAYS = 'launched-in-days',
    ENDING_IN_DAYS = 'ending-in-days',
    PACING_PERCENT_UNDER = 'pacing-percent-under',
    UNDERSPEND_RISK_HIGHER_THAN = 'underspend-risk-higher-than',
    VIEWABILITY_UNDER = 'viewability-under',
    CPM_HIGHER_THAN = 'cpm-higher-than',
    CPC_HIGHER_THAN = 'cpc-higher-than',
    DROPPED_IN_DAILY_SPEND = 'dropped-in-daily-spend',
    DROPPED_IN_DAILY_IMPRESSIONS = 'dropped-in-daily-impressions',
    CTR_UNDER = 'ctr-under',
    AVG_FREQUENCY_HIGHER = 'avg-frequency-higher',
}
