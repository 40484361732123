/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdGroupSplitConditionOperators {
    IN = 'in',
    NOT_IN = 'not_in',
    ALL = 'all',
    GREATER_EQUAL = 'greater-equal',
}
