/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DeviceType {
    NONE = 'none',
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    SMARTPHONE = 'smartphone',
    MOBILE = 'mobile',
    TV = 'tv',
}
