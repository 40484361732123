/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AudienceType {
    CONVERSION = 'conversion',
    RETARGETING = 'retargeting',
    CUSTOM = 'custom',
    WEB = 'web',
    PRESCRIBER = 'prescriber',
    DEMOGRAPHICS = 'demographics',
    IB_M__WEATHER = 'ib-m_-weather',
    CONSUMER = 'consumer',
    LOCATION = 'location',
    BRAND__SAFETY = 'brand_-safety',
    PROFESSIONAL = 'professional',
}
