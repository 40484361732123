/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EntityStatus {
    ACTIVE = 'active',
    REMOVED = 'removed',
    DISABLED = 'disabled',
    PAUSED = 'paused',
    DRAFT = 'draft',
    EXTERNAL = 'external',
    PROCESSING = 'processing',
    ERROR = 'error',
}
