/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AudienceStatus {
    PROCESSING = 'processing',
    ACTIVE = 'active',
    FAILED = 'failed',
    DRAFT = 'draft',
    DTC_AWAITS_MANUAL_PROCESSING = 'dtc-awaits-manual-processing',
}
