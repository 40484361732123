/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AudienceTarget {
    HEALTHCARE_PROVIDER = 'healthcare-provider',
    PATIENT_CONSUMER = 'patient-consumer',
    PROFESSIONAL_NON_HCP = 'professional-non-hcp',
    CAREGIVER = 'caregiver',
}
