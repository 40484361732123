/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SupplyType {
    NONE = 'none',
    WEB = 'web',
    MOBILE_WEB = 'mobile_web',
    MOBILE_APP = 'mobile_app',
}
