/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DateRangeFilter {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    THIS_WEEK = 'this-week',
    LAST_WEEK = 'last-week',
    LAST7_DAYS = 'last7-days',
    LAST14_DAYS = 'last14-days',
    LAST30_DAYS = 'last30-days',
    LAST_MONTH = 'last-month',
    THIS_MONTH = 'this-month',
    THIS_QUARTER = 'this-quarter',
    LAST_QUARTER = 'last-quarter',
    EXACT_DAY = 'exact-day',
    EXACT_RANGE = 'exact-range',
    THIS_YEAR = 'this-year',
    LAST_YEAR = 'last-year',
    LAST90_DAYS = 'last90-days',
    ALL_TIME = 'all-time',
}
